import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import {
  AppUsageContract,
  CalendarEntry,
  LockableAction,
  Resource,
  User,
} from '../../../typings/backend-types';
dayjs.extend(isSameOrAfter);
import { hasReadWriteAccessTo } from '../../../utilities/access-rights/hasReadWriteAccessTo';
import authenticatedFetcher from '../../../data/authenticatedFetcher';
import { HttpEndpoints } from '../../../data/httpEndpoints';
import { Permission, Scope } from '../../../typings/roleConfig';
import { DateSpan } from './CalendarHelpers';

/**
 * Checks whether the given user has the permission to edit / create / delete events in the given timespan
 * Permissions by role are listed here: https://www.notion.so/dot9-software/Access-rights-by-roles-de6d582b96f8495eb1206deea0c48aa7
 */
export async function canEditInTime(
  dateSpan: DateSpan,
  user: User,
): Promise<boolean> {
  if (
    await authenticatedFetcher(
      HttpEndpoints.LockPeriods.check(
        user.organization.id,
        dateSpan.start.format('YYYY-MM-DD'),
        dateSpan.end.format('YYYY-MM-DD'),
        LockableAction.CALENDAR_EDITS,
      ),
    )
  ) {
    return false;
  }
  return canEditWithoutLocks(dateSpan.start, user);
}
export function canEditWithoutLocks(start: Dayjs, user: User): boolean {
  if (
    user.role.isAdmin ||
    user.role.permissions[Permission.EDIT_PAST_EVENTS] >= Scope.READ_WRITE
  ) {
    return true;
  }
  return start.isSameOrAfter(dayjs().startOf('day'));
}

export async function canEditEvent(
  event: CalendarEntry,
  user: User,
  orgContract: AppUsageContract | null,
): Promise<boolean> {
  if (!event?.user || !user) return false;
  if (!hasReadWriteAccessTo(Resource.Calendar, user, event.user, orgContract)) {
    return false;
  }
  // users can only edit their own vacation if they have the permission
  if (
    dayjs(event.start_time).isBefore(dayjs(event.end_time).startOf('day')) && // event is vacation
    event.user.id === user.id && // user is owner
    !user.role.isAdmin &&
    user.role.permissions[Permission.OWN_VACATION] < Scope.READ_WRITE
  ) {
    return false;
  }
  return canEditInTime(
    {
      start: dayjs(event.start_time),
      end: dayjs(event.end_time),
    },
    user,
  );
}

import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { EventContentArg, EventInput } from '@fullcalendar/common';
import { CSSProperties } from 'react';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { nanoid } from 'nanoid';
import { DateSpanApi, EventApi } from '@fullcalendar/react';
import { t } from 'i18next';
import { classNames } from '../../../utilities/classNames';
import { LoadingIndicator } from '../../Common/LoadingIndicator';
import authenticatedFetcher from '../../../data/authenticatedFetcher';
import { HttpEndpoints } from '../../../data/httpEndpoints';
import {
  User,
  VacationEvent,
  WorkEvent,
  AccessRight,
  Resource,
  ICalSubscription,
  ICalEvent,
} from '../../../typings/backend-types';
import { displayDateFormat } from '../../../utilities/dateFormat';
import { EventColors } from './EventColors';
import { CalendarView } from './getCalendarView';
import { ConfirmPopupInfo } from 'context/confirmPopupContext';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export interface DateSpan {
  start: Dayjs;
  end: Dayjs;
}

export function popupPositionStyle(position: [number, number]): CSSProperties {
  return {
    left: position
      ? Math.max(
          position[0] - (position[0] + 400 > window.innerWidth ? 500 : 0),
          10,
        ) + 'px'
      : 0,
    top: position
      ? Math.max(10, Math.min(position[1], window.innerHeight - 610)) + 'px'
      : 0,
    maxHeight: Math.min(590, window.innerHeight - 15),
    overflowY: `auto`,
    width: Math.min(400, window.innerWidth - 10),
  };
}

export function renderEventContent(
  eventInfo: EventContentArg,
  isLoading: boolean,
  calendarView: CalendarView,
): JSX.Element {
  const shadowTime = eventInfo.event?.extendedProps?.shadow;
  const shadowTimeRem = shadowTime && shadowTime * 0.054; // magic number determined by trial and error
  const eventPrefixText = getEventPrefixText(eventInfo);
  if (isLoading) {
    return (
      <div className="h-full flex justify-center items-center select-none">
        <LoadingIndicator size={6} thickness={4} color="white" />
      </div>
    );
  }
  if (eventInfo.event.allDay) {
    return (
      <p
        className={classNames(
          'px-2 font-medium',
          calendarView === CalendarView.MONTH && 'text-xs',
        )}
      >
        {eventPrefixText || eventInfo.event?.title}
        {eventPrefixText && (
          <i className="text-gray-600 font-normal ml-1.5">
            {eventInfo.event?.title}
          </i>
        )}
      </p>
    );
  }
  if (calendarView === CalendarView.MONTH) {
    return (
      <>
        <div
          className="p-[2px] text-ellipsis overflow-hidden rounded"
          style={{
            color: eventInfo.textColor,
            backgroundColor: eventInfo.backgroundColor,
          }}
        >
          <div
            className="px-1 h-full text-ellipsis overflow-hidden font-normal text-xs max-h-12 select-none rounded border-l-4 border-white"
            style={{
              color: eventInfo.textColor,
              backgroundColor: eventInfo.backgroundColor,
            }}
          >
            <p className="font-medium whitespace-normal text-ellipsis overflow-hidden">
              <span className="font-normal text-gray-700 mr-2">
                {eventInfo.timeText}
              </span>
              {eventInfo.event?.title}
            </p>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="px-2 h-full overflow-hidden font-normal text-xs border-l-4 border-white select-none">
        <p className="font-medium">{eventInfo.event?.title}</p>
        <p className="font-medium text-gray-700">{eventInfo.timeText}</p>
        <p className="text-gray-700">
          {eventInfo.event?.extendedProps?.description ?? ''}
        </p>
        <p className="text-gray-700 mb-1">
          {eventInfo.event?.extendedProps?.vehicle}
        </p>
      </div>
      {!!shadowTimeRem && (
        <div
          style={{
            height: shadowTimeRem + 'rem',
            bottom: -shadowTimeRem + 'rem',
          }}
          className={classNames('w-full striped-gray left-0 rounded-b-md')}
        />
      )}
    </>
  );
}

/**
 * Event prefix is used for all day events and it is either holiday, vacation or empty string
 */
function getEventPrefixText(eventInfo: EventContentArg): string {
  if (eventInfo.event?.extendedProps?.isHoliday) {
    return t('calendarPopups.common.eventPrefixHoliday');
  } else if (eventInfo.event?.extendedProps?.isIcalEvent) {
    return '';
  }
  return t('calendarPopups.common.eventPrefixVacation');
}

/**
 * parses a time in the format hh:mm to a number of hours
 */
export function timeStringToNumber(time: string): number {
  const date = dayjs(time, 'HH:mm');
  return date.hour() + date.minute() / 60.0;
}

export async function checkForOverlappingVacations(
  userId: string,
  newVacation: VacationEvent,
  timespan: DateSpan,
): Promise<boolean> {
  const vacationTimeSpan = {
    timespanBegin: timespan.start.toISOString(),
    timespanEnd: timespan.end.toISOString(),
  };

  const overlappingVacations: VacationEvent[] = (
    await authenticatedFetcher(
      HttpEndpoints.VacationEventEndpoints.getVacationEventsForUser(
        userId,
        vacationTimeSpan,
      ),
    )
  ).filter((v: VacationEvent) => v.id !== newVacation?.id);

  return overlappingVacations.length > 0;
}

export async function validateVacation(
  organizationId: string,
  vacationStart: Dayjs,
  vacationEnd: Dayjs,
  confirm: (n: ConfirmPopupInfo) => Promise<void> | void,
): Promise<boolean> {
  if (isOnWeekend({ start: vacationStart, end: vacationEnd })) {
    try {
      await confirm({
        title: t('calendarPopups.common.warning'),
        message: t('calendarPopups.common.vacationOnWeekend'),
      });
    } catch {
      return false;
    }
  }

  const overlappingOrgHolidays = await getOverlappingOrganizationHolidays(
    organizationId,
    {
      start: vacationStart,
      end: vacationEnd.subtract(1, 'day'),
    },
  );

  if (overlappingOrgHolidays.length > 0) {
    try {
      await confirm({
        title: t('calendarPopups.common.warning'),
        message: t('calendarPopups.common.holidaysInVacation', {
          holidays: overlappingOrgHolidays.join(', '),
        }),
      });
    } catch {
      return false;
    }
  }
  return true;
}

export async function getOverlappingOrganizationHolidays(
  orgId: string,
  timespan: { start: Dayjs; end: Dayjs },
): Promise<string[]> {
  const overlappingHolidays: Dayjs[] = [];

  const { holidays: holidays } = await authenticatedFetcher(
    HttpEndpoints.OrganizationEndpoints.getOrganizationById(orgId),
  );

  const startMonth: string = timespan.start.format('YYYY-MM');
  const endMonth: string = timespan.end.format('YYYY-MM');
  for (const i in holidays) {
    if (i < startMonth || i > endMonth) continue;
    for (const k in holidays[i]) {
      const holidayDate = dayjs(i + '-' + holidays[i][k]);
      if (
        holidayDate.isSameOrAfter(timespan.start, 'day') &&
        holidayDate.isSameOrBefore(timespan.end, 'day')
      ) {
        overlappingHolidays.push(holidayDate);
      }
    }
  }
  return overlappingHolidays
    .sort((a, b) => (a.isAfter(b) ? 1 : -1))
    .map((date) => date.format(displayDateFormat));
}

export function isOnWeekend(timespan: { start: Dayjs; end: Dayjs }): boolean {
  const numberVacationDays = timespan.end.diff(timespan.start, 'day');

  if (numberVacationDays >= 6) {
    return true;
  }
  for (let i = 0; i < numberVacationDays; i++) {
    if (
      timespan.start.add(i, 'day').day() === 0 ||
      timespan.start.add(i, 'day').day() === 6
    ) {
      return true;
    }
  }
  return false;
}

/**
 * Return users where the current user has access to their calendars.
 * Note: Accessible calendars are considered calendars with read or read & write access rights
 * @param currentUser
 * @returns Users whose calendars are considered accessible
 */
export function getAccessibleCalendarUsers(currentUser: User): User[] {
  const accessibleUsers: User[] = [];
  if (currentUser?.access_rights) {
    currentUser.access_rights.forEach((right) => {
      if (
        right.resource === Resource.Calendar &&
        (right.access_right === AccessRight.Read ||
          right.access_right === AccessRight.ReadWrite)
      ) {
        accessibleUsers.push(right.accessed_user);
      }
    });
    return accessibleUsers;
  }
}

/**
 * check whether a calendar event is a full day event,
 * i.e. if the start time and the end time have equal or more than 24 hours differences
 */
export function isFullDayEvent(start: string, end: string): boolean {
  return dayjs(end).diff(dayjs(start), 'hour') >= 24;
}

export function getWorkEventDescription(e: WorkEvent): string {
  return !!e.student
    ? `${e.student.lastName ?? ''}, ${e.student.firstName ?? ''}`
    : e.description;
}

export function isHoliday(
  date: dayjs.Dayjs,
  holidays?: Record<string, number[]>,
): boolean {
  return holidays?.[date.format('YYYY-MM')]?.includes(date.date()) ?? false;
}

export function isVacation(date: dayjs.Dayjs, events?: EventInput[]): boolean {
  const isVacation = events?.some(
    (e: EventInput) =>
      e.additionalProps?.isVacation &&
      date.startOf('d').isSameOrAfter(dayjs(e.start_time).startOf('d')) &&
      date.endOf('d').isSameOrBefore(dayjs(e.end_time)),
  );
  return isVacation || false;
}

export function getIcalSubscriptionIds(icalSubs: ICalSubscription[]): string[] {
  return icalSubs?.map((s) => {
    return s.id;
  });
}

export function calculatePopupPosition(
  interaction: { x: number; y: number } | { clientX: number; clientY: number },
): [number, number] {
  if (window.TouchEvent && interaction instanceof TouchEvent) {
    return [
      interaction.changedTouches[0].clientX + 50,
      interaction.changedTouches[0].clientY - 200,
    ];
  } else if ('x' in interaction && 'y' in interaction) {
    return [interaction.x + 50, interaction.y - 200];
  }
}

export function mapHolidaysToEventInputs(
  viewStartDate: Dayjs,
  viewEndDate: Dayjs,
  data: Record<string, number[]>,
): EventInput[] {
  const holidays: EventInput[] = [];
  for (const [yearMonth, days] of Object.entries(data)) {
    for (const d of days) {
      const date = dayjs(yearMonth, 'YYYY-MM').date(d);
      if (
        date.isSameOrAfter(viewStartDate, 'day') &&
        date.isSameOrBefore(viewEndDate, 'day')
      ) {
        holidays.push({
          start: dayjs(yearMonth, 'YYYY-MM').date(d).toDate(),
          allDay: true,
          backgroundColor: EventColors.blue.bg,
          textColor: EventColors.blue.text,
          borderColor: EventColors.blue.bg,
          editable: false,
          extendedProps: {
            isHoliday: true,
          },
        });
      }
    }
  }
  return holidays;
}

export function mapIcalEventsToEventInputs(
  data: ICalEvent[],
  icalSubscriptions: ICalSubscription[],
): EventInput[] {
  return data.map((icalevent) => {
    const displayColor = getDisplayColor(icalevent, icalSubscriptions);
    const isAllDay = isFullDayEvent(icalevent.start, icalevent.end);
    return {
      id: nanoid(),
      start: dayjs(icalevent.start).toDate(),
      end: dayjs(icalevent.end).toDate(),
      title: ignoreIcalFieldParameters(icalevent.summary),
      description: ignoreIcalFieldParameters(icalevent.description),
      editable: false,
      extendedProps: {
        isIcalEvent: true,
      },
      allDay: isAllDay,
      backgroundColor: EventColors[displayColor]?.bg ?? EventColors.blue.bg,
      textColor: EventColors[displayColor]?.text ?? EventColors.blue.text,
      borderColor: EventColors[displayColor]?.bg ?? EventColors.blue.bg,
    } as EventInput;
  });
}

export function mapWorkEventsToEventInputs(data: WorkEvent[]): EventInput[] {
  return data.map(mapWorkEventToEventInput);
}

export function mapWorkEventToEventInput(event: WorkEvent): EventInput {
  const displayColor = EventColors[event.user.calendar_color];
  return {
    id: event.id,
    start: dayjs(event.start_time).toDate(),
    end: dayjs(event.end_time).toDate(),
    title: event.billingType?.title ?? '',
    description: getWorkEventDescription(event),
    extendedProps: {
      shadow: event.billingType?.shadow_time,
      vehicle: event.vehicle?.title || event.vehicle?.license_plate,
      isWorkEvent: true,
      userId: event.user.id,
    },
    allDay: false,
    backgroundColor: displayColor?.bg ?? EventColors.blue.bg,
    textColor: displayColor?.text ?? EventColors.blue.text,
    borderColor: displayColor?.bg ?? EventColors.blue.bg,
  } as EventInput;
}

export function mapVacationEventsToEventInputs(
  data: VacationEvent[],
): EventInput[] {
  return data.map((event) => {
    const displayColor = EventColors[event.user.calendar_color];
    return {
      id: event.id,
      start: dayjs(event.start_time).toDate(),
      end: dayjs(event.end_time).toDate(),
      title: event.description ?? '',
      description: '',
      extendedProps: {
        isVacationEvent: true,
        userId: event.user.id,
      },
      allDay: true,
      backgroundColor: displayColor?.bg ?? EventColors.blue.bg,
      textColor: displayColor?.text ?? EventColors.blue.text,
      borderColor: displayColor?.bg ?? EventColors.blue.bg,
    } as EventInput;
  });
}

function getDisplayColor(
  icalevent: ICalEvent,
  icalSubscriptions: ICalSubscription[],
) {
  return icalSubscriptions.find((s) => s.id === icalevent.subscriptionId)
    .display_color;
}

/**
 * Ingores parameters from .ics fields (such as language) by extracting
 * and returning just the value of the .ics field
 * See more here: https://icalendar.org/iCalendar-RFC-5545/3-2-10-language.html
 * @param field an .ics field
 * @returns plain field text as a string
 */
function ignoreIcalFieldParameters(field: string | { val: string }): string {
  if (typeof field === 'object' && field.val != undefined) {
    return field.val as string;
  }
  return field as string;
}

export function eventHasPossibleTimespan(
  event: DateSpanApi | EventApi,
): boolean {
  return (
    event.allDay ||
    (event.start.getDate() === event.end.getDate() &&
      event.start.getMonth() === event.end.getMonth() &&
      event.start.getFullYear() === event.end.getFullYear())
  );
}

export function getTimeSpan(
  startTime: string,
  endTime: string,
  date: string,
): DateSpan | null {
  if (!startTime || !endTime || !date || !dayjs(date).isValid()) {
    return null;
  }

  const endMoment = dayjs(endTime, 'HH:mm');
  const startMoment = dayjs(startTime, 'HH:mm');

  const startStamp = dayjs(date)
    .hour(startMoment.hour())
    .minute(startMoment.minute());
  const endStamp = dayjs(date)
    .hour(endMoment.hour())
    .minute(endMoment.minute());

  return { start: startStamp, end: endStamp };
}

export function formatCalendarRangeTitle(
  start: Date,
  end: Date,
  view: CalendarView,
): string {
  const startMoment = dayjs(start);
  const endMoment = dayjs(end).subtract(1, 'second');

  if (view === CalendarView.WEEK || view === CalendarView.THREE_DAYS) {
    if (startMoment.isSame(endMoment, 'month')) {
      return `${startMoment.format('DD.')} - ${endMoment.format(
        'DD. MMM YYYY',
      )}`;
    }
    if (startMoment.isSame(endMoment, 'year')) {
      return `${startMoment.format('DD. MMM')} - ${endMoment.format(
        'DD. MMM YYYY',
      )}`;
    }
    return `${startMoment.format('DD. MMM YYYY')} - ${endMoment.format(
      'DD. MMM YYYY',
    )}`;
  }

  if (view === CalendarView.DAY) {
    return `${startMoment.format('DD. MMM YYYY')}`;
  }

  return `${startMoment.format('MMMM YYYY')}`;
}

import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import useSWR from 'swr';
import noop from 'lodash/noop';
import { EventDropArg } from '@fullcalendar/react';
import { useTranslation } from 'react-i18next';
import authenticatedFetcher from '../../../../data/authenticatedFetcher';
import { HttpEndpoints } from '../../../../data/httpEndpoints';
import { useAppContext } from '../../../../context/appContext';
import { CalendarPopupWrapper } from '../Common/CalendarPopupWrapper';

import { CalendarSelection } from '../Common/CalendarSelection';
import { LoadingIndicator } from '../../../Common/LoadingIndicator';
import { validateMoveOrCopyEvent } from '../../helpers/validateMoveEvent';
import {
  BasicTextInput,
  dateInputFormat,
  timeInputFormat,
} from '../../../Forms/FormFields/BasicTextInput';
import { Form } from '../../../Forms/Form';
import { InfoText } from '../../../Common/InfoText';
import authenticatedPost from '../../../../data/authenticatedPost';
import { useErrorPopupContext } from '../../../../context/errorPopupContext';
import { CustomError } from '../../../../data/customError';
import { useCalendarOverrideContext } from '../../../../context/calendarOverrideContext';
import useContract from '../../../../hooks/useContract';
import useUserPermission from '../../../../hooks/useUserPermission';
import { Permission, Scope } from '../../../../typings/roleConfig';
import { Color, CreateWorkEventDto, WorkEvent } from '@tr-types/backend-types';
import { displayDateFormat } from 'utilities/dateFormat';

interface Props {
  position: [number, number];
  onClose: () => void;
  calendarSelection: CalendarSelection;
  reloadEvents: () => void;
}

export const WorkEventCopyPopup: FunctionComponent<Props> = ({
  position,
  onClose,
  calendarSelection,
  reloadEvents,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendarPopups.copyWorkEvent',
  });

  const { user: loggedInUser } = useAppContext();
  const { contract } = useContract();
  const { setErrorMessage } = useErrorPopupContext();
  const { data: originalEvent } = useSWR<WorkEvent>(
    () =>
      HttpEndpoints.WorkEventEndpoints.getWorkEventById(
        calendarSelection.eventId,
        calendarSelection.userId,
      ),
    authenticatedFetcher,
  );

  const { overrideUser } = useCalendarOverrideContext();
  const canEditPastEvents = useUserPermission(
    Permission.EDIT_PAST_EVENTS,
    Scope.READ_WRITE,
  );

  const [date, setDate] = useState<string>('');
  const [startDate, setStartDate] = useState<Dayjs>(
    dayjs(originalEvent?.start_time).add(1, 'week'),
  );
  const [endDate, setEndDate] = useState<Dayjs>(
    dayjs(originalEvent?.end_time).add(1, 'week'),
  );
  const [validationMessage, setValidationMessage] = useState<string>('');

  useEffect(() => {
    if (!startDate || !endDate || !startDate.isValid() || !endDate.isValid()) {
      return;
    }
    validateMoveOrCopyEvent(
      {
        event: { start: startDate.toDate(), end: endDate.toDate() },
      } as EventDropArg,
      originalEvent,
      noop,
      loggedInUser,
      'copy',
      contract,
    ).then((v) => setValidationMessage(v), noop);
  }, [startDate, endDate, loggedInUser, overrideUser, originalEvent, contract]);

  const eventDurationMinutes = useMemo(
    () =>
      dayjs(originalEvent.end_time).diff(originalEvent.start_time, 'minutes'),
    [originalEvent],
  );

  function getEventPopupHeaderText(): string {
    if (!originalEvent) return t('loadingLabel');
    return t('title', {
      date: dayjs(originalEvent.start_time).format(displayDateFormat),
      from: dayjs(originalEvent.start_time).format('HH:mm'),
      to: dayjs(originalEvent.end_time).format('HH:mm'),
    });
  }

  function onDateChange(e: ChangeEvent<HTMLInputElement>) {
    const newDate = e.target.value;
    setDate(newDate);
    if (!startDate.isValid() || !newDate) return;
    const newStart = dayjs(newDate, dateInputFormat)
      .hour(startDate.hour())
      .minute(startDate.minute());
    setStartDate(newStart);
    setEndDate(newStart.add(eventDurationMinutes, 'minutes'));
  }
  function onStartTimeChange(e: ChangeEvent<HTMLInputElement>) {
    const newTime = dayjs(e.target.value, timeInputFormat);
    if (!date) return;
    const newStart = dayjs(date).hour(newTime.hour()).minute(newTime.minute());
    setEndDate(newStart.add(eventDurationMinutes, 'minutes'));
    setStartDate(newStart);
  }

  async function onSubmit() {
    if (validationMessage) return;
    delete originalEvent.id;
    const copiedEvent: CreateWorkEventDto = {
      billingTypeId: originalEvent.billingType.id,
      organizationId: originalEvent.organization.id,
      user: originalEvent.user,
      start_time: startDate.format(),
      end_time: endDate.format(),
      description: originalEvent.description,
      student: originalEvent.student,
      vehicleId: originalEvent.vehicle?.id,
      secondaryVehicleId: originalEvent.secondaryVehicle?.id,
      meetingPointId: originalEvent.meetingPoint?.id,
      meetingAddress: originalEvent.meetingAddress,
    };
    try {
      await authenticatedPost(
        HttpEndpoints.WorkEventEndpoints.postWorkEvent(originalEvent.user?.id),
        copiedEvent,
      );
    } catch (e) {
      if (e instanceof CustomError && e.message) {
        setErrorMessage(t('errorCopyEvent', { msg: e.message }));
      } else {
        setErrorMessage(t('errorCopyEventGeneric'));
      }
    }
    reloadEvents();
    onClose();
  }

  return (
    <CalendarPopupWrapper
      position={position}
      onClose={onClose}
      isOpen
      icon={ClipboardDocumentIcon}
      title={getEventPopupHeaderText()}
    >
      {!!originalEvent ? (
        <>
          <InfoText color={Color.Red} className="mb-2">
            {validationMessage}
          </InfoText>
          <Form onSubmit={onSubmit} saveDisabled={!!validationMessage}>
            <BasicTextInput
              type="date"
              label={t('dateLabel')}
              required
              onChange={onDateChange}
              min={canEditPastEvents ? null : dayjs().format(dateInputFormat)}
              value={startDate.format(dateInputFormat)}
            />
            <BasicTextInput
              type="time"
              required
              label={t('startTimeLabel')}
              value={startDate.format(timeInputFormat)}
              onChange={onStartTimeChange}
            />
          </Form>
        </>
      ) : (
        <LoadingIndicator />
      )}
    </CalendarPopupWrapper>
  );
};

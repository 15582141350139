import {
  User,
  AccessRight,
  Resource,
  AppUsageContract,
  BasicUserInfo,
} from '../../typings/backend-types';
import { hasAccessTo } from './hasAccessTo';

/**
 * Determine if the current user has read & write access to the specified resource
 * @param resource
 * @param currentUser
 * @param otherUser
 * @returns true if the access right for the matched user is read & write
 */

export function hasReadWriteAccessTo(
  resource: Resource,
  currentUser: User | undefined | null,
  otherUser: BasicUserInfo | undefined | null,
  orgContract: AppUsageContract | null,
): boolean {
  return hasAccessTo(
    resource,
    AccessRight.ReadWrite,
    currentUser,
    otherUser,
    orgContract,
  );
}
